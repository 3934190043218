import React from "react";
import type { Nullable } from "../../@types/generic";
import { getUrl } from "../../helpers/urls/getUrl";
import { sendError } from "../ErrorBoundary/helpers";
import { Image } from "../Image";

type Props = {
    path: Nullable<string>;
    alt: string;
    title?: Nullable<string>;
    imgTagSize?: { width: number; height: number };
    lazyloadImage?: boolean;
    className?: string;
};

export const WeatherIcon: React.FC<Props> = ({
    path,
    alt,
    title,
    imgTagSize = { width: 44, height: 38 },
    lazyloadImage = true,
    className,
}) => {
    try {
        return (
            <Image
                className={className}
                width={`${imgTagSize.width.toString()}px`}
                height={`${imgTagSize.height.toString()}px`}
                imgTagSize={imgTagSize}
                src={getUrl({
                    type: "weatherState",
                    path: `/${path ?? "d_default.svg"}`,
                })}
                alt={alt || "Icon mit Wetterzustand"}
                title={title ?? ""}
                lazy={lazyloadImage}
            />
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
