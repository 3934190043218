"use client";

import NextLink from "next/link";
import React, { useCallback, useState } from "react";
import type { Nullable } from "../../@types/generic";
import dayjs, { getTimezone } from "../../app/common/dayjs";
import reviewMap from "../../app/common/skiAreaReviewMapping";
import { Webview } from "../../bridge/types";
import { cn } from "../../helpers/classNames";
import { formatDistance } from "../../helpers/formatters/formatDistance";
import { formatTemperature } from "../../helpers/formatters/temperature";
import { getRoute } from "../../helpers/urls/getRoute";
import { getUrl } from "../../helpers/urls/getUrl";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { useForecastWeatherNearby } from "../../network/matlocq/queries/ForecastWeatherNearby";
import type { ForecastWeatherNearby } from "../../network/matlocq/types/forecastWeatherNearby";
import type { SkiArea } from "../../network/matlocq/types/skiArea";
import { useCountry, useIsWebview } from "../ApplicationState/hooks";
import { AvalancheInformation } from "../AvalancheInformation";
import { sendError } from "../ErrorBoundary/helpers";
import { Image } from "../Image";
import { NavigateButton } from "../NavigateButton";
import { SkiAreaOpeningBadge } from "../SkiAreaOpeningBadge";
import { SpinningSun } from "../SpinningSun";
import { WeatherIcon } from "../WeatherIcon";
import styles from "./item.module.scss";

type Props = {
    skiArea: SkiArea;
    preloadImage: boolean;
};

export const Item: React.FC<Props> = ({ skiArea, preloadImage }) => {
    const country = useCountry();
    const [element, setElement] = useState<Nullable<HTMLLIElement>>(null);
    const intersectionObserver = useIntersectionObserver(element, {
        freezeOnceVisible: true,
    });
    const isEnabled = intersectionObserver?.isIntersecting ?? false;
    const { data: forecast, isLoading: isLoadingForecast } =
        useForecastWeatherNearby({
            latitude: skiArea.coordinates.mountain.latitude ?? undefined,
            longitude: skiArea.coordinates.mountain.longitude ?? undefined,
            // fallback to false is necessary because otherwise enabled would be true since isIntersecting is initially undefined
            enabled: isEnabled,
        });
    const isWebview = useIsWebview();

    const ref = useCallback((node: Nullable<HTMLLIElement>) => {
        if (node !== null) {
            setElement(node);
        }
    }, []);

    try {
        let imageUrl = getUrl({
            type: "thumbnails",
            path: `skiarea_${skiArea.id}/400_100.webp`,
        });
        const href = getRoute({
            parameters: {
                pageName: "skiAreaDetail",
                countryName: skiArea.normalized.countryName,
                name: skiArea.normalized.name,
                id: skiArea.id,
            },
            searchParameters: isWebview ? { webview: Webview.app } : undefined,
            country,
        });

        if (
            skiArea.sponsoring.isSponsored &&
            typeof skiArea.sponsoring.headerImageUrl === "string"
        ) {
            imageUrl = skiArea.sponsoring.headerImageUrl;
        }

        return (
            <li className={styles.wrapper} ref={ref}>
                {isWebview ? (
                    <NavigateButton url={href} className={styles.link}>
                        <SubTree
                            skiArea={skiArea}
                            preloadImage={preloadImage}
                            forecast={forecast}
                            isLoadingForecast={isLoadingForecast}
                            isEnabled={isEnabled}
                            imageUrl={imageUrl}
                        />
                    </NavigateButton>
                ) : (
                    <NextLink className={styles.link} href={href}>
                        <SubTree
                            skiArea={skiArea}
                            preloadImage={preloadImage}
                            forecast={forecast}
                            isLoadingForecast={isLoadingForecast}
                            isEnabled={isEnabled}
                            imageUrl={imageUrl}
                        />
                    </NextLink>
                )}
            </li>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};

const SubTree: React.FC<
    Props & {
        forecast: Nullable<ForecastWeatherNearby> | undefined;
        isLoadingForecast: boolean;
        isEnabled: boolean;
        imageUrl: string;
    }
> = ({
    skiArea,
    preloadImage,
    forecast,
    isLoadingForecast,
    isEnabled,
    imageUrl,
}) => {
    const country = useCountry();
    const timezone = getTimezone(forecast?.location.timezone, country);
    return (
        <>
            <Image
                src={imageUrl}
                alt={`Thumbnail von Skigebiet ${skiArea.name}`}
                title={`Thumbnail von Skigebiet ${skiArea.name}`}
                width="100%"
                height={skiArea.sponsoring.isSponsored ? "auto" : "100px"}
                preload={preloadImage}
            />
            <div className={styles.infoBadges}>
                {typeof skiArea.avalancheInformation.warningLevel ===
                    "number" && (
                    <AvalancheInformation
                        minimumShowLevel={2}
                        warningLevel={skiArea.avalancheInformation.warningLevel}
                    />
                )}
                {skiArea.meta?.distance && (
                    <div className={styles.distanceBadge}>
                        <span
                            className={cn(styles.distanceBadgeIcon, "icon-pin")}
                        />
                        {formatDistance(skiArea.meta.distance * 1000, {
                            unit: "km",
                        })}
                    </div>
                )}
            </div>
            <div className={styles.information}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.headline}>{skiArea.name}</div>
                        <div className={styles.subHeadline}>
                            {skiArea.locationInformation.region.name}
                        </div>
                        <SkiAreaOpeningBadge isOpen={skiArea.season.open} />
                    </div>
                    <div className={styles.right}>
                        {!isEnabled ? null : isLoadingForecast ? (
                            <SpinningSun size={20} />
                        ) : (
                            <>
                                <WeatherIcon
                                    path={
                                        forecast?.items[0]?.summary.weather
                                            .icon ?? ""
                                    }
                                    alt={
                                        forecast?.items[0]?.summary.weather
                                            .text ?? ""
                                    }
                                    title={
                                        forecast?.items[0]?.summary.weather
                                            .text ?? ""
                                    }
                                />
                                <span className={styles.temperatureHigh}>
                                    {formatTemperature(
                                        forecast?.items[0]?.summary.temperature
                                            .max
                                    )}
                                </span>
                                <span className={styles.temperatureLow}>
                                    <span className={styles.textSeparator}>
                                        /
                                    </span>
                                    {formatTemperature(
                                        forecast?.items[0]?.summary.temperature
                                            .min
                                    )}
                                </span>
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.informationRow}>
                    <div className={styles.informationText}>
                        <span className="snow-icon-calendar"></span>
                        Saison
                    </div>
                    <div className={styles.informationTextBold}>
                        {`${
                            skiArea.season.start
                                ? dayjs(skiArea.season.start)
                                      .tz(timezone)
                                      .format("DD.MM.")
                                : "k.A."
                        } - ${
                            skiArea.season.end
                                ? dayjs(skiArea.season.end)
                                      .tz(timezone)
                                      .format("DD.MM.")
                                : "k.A."
                        }`}
                    </div>
                </div>
                <div className={styles.informationRow}>
                    <div className={styles.informationText}>
                        <span className="snow-icon-mountain-height"></span>
                        Höhe Berg
                    </div>
                    <div
                        className={styles.informationTextBold}
                    >{`${skiArea.coordinates.mountain.elevation?.toString() ?? ""} m`}</div>
                </div>
                <div className={styles.informationRow}>
                    <div className={styles.informationText}>
                        <span className="snow-icon-skiarea"></span>
                        Pisten
                    </div>
                    {skiArea.slopeMeters.overall !== null ? (
                        <div className={styles.slopes}>
                            {skiArea.slopeMeters.overall === 0 ? null : (
                                <div className={styles.slopeLengthIndicator}>
                                    {skiArea.slopeMeters.blue !== null ? (
                                        <div
                                            className={cn(
                                                styles.slope,
                                                styles.slopeColorBlue
                                            )}
                                            style={{
                                                width: `${(
                                                    (skiArea.slopeMeters.blue /
                                                        skiArea.slopeMeters
                                                            .overall) *
                                                    100
                                                ).toFixed(2)}%`,
                                            }}
                                        />
                                    ) : null}
                                    {skiArea.slopeMeters.red !== null ? (
                                        <div
                                            className={cn(
                                                styles.slope,
                                                styles.slopeColorRed
                                            )}
                                            style={{
                                                width: `${(
                                                    (skiArea.slopeMeters.red /
                                                        skiArea.slopeMeters
                                                            .overall) *
                                                    100
                                                ).toFixed(2)}%`,
                                            }}
                                        />
                                    ) : null}
                                    {skiArea.slopeMeters.black !== null ? (
                                        <div
                                            className={cn(
                                                styles.slope,
                                                styles.slopeColorBlack
                                            )}
                                            style={{
                                                width: `${(
                                                    (skiArea.slopeMeters.black /
                                                        skiArea.slopeMeters
                                                            .overall) *
                                                    100
                                                ).toFixed(2)}%`,
                                            }}
                                        />
                                    ) : null}
                                </div>
                            )}
                            <div
                                className={cn(
                                    styles.informationTextBold,
                                    "value"
                                )}
                            >{`${(
                                skiArea.slopeMeters.overall / 1000
                            ).toString()} km`}</div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={styles.reviewContainer}>
                {skiArea.reviews.map((item) =>
                    item.tags === null ? null : (
                        <span
                            className={styles.reviewItem}
                            key={item.type}
                            title={reviewMap[item.type].text}
                        >
                            <span className={reviewMap[item.type].icon} />
                        </span>
                    )
                )}
            </div>
        </>
    );
};
