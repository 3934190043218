"use client";

import { useRouter, useSearchParams } from "next/navigation";
import React from "react";
import dayjs from "../../app/common/dayjs";
import reviewMap from "../../app/common/skiAreaReviewMapping";
import { cn } from "../../helpers/classNames";
import { getRoute } from "../../helpers/urls/getRoute";
import { useLocation } from "../../network/matlocq/queries/Location";
import { useSkiAreaCountries } from "../../network/matlocq/queries/SkiAreaCountries";
import type { SkiAreaReviewType } from "../../network/matlocq/types/skiArea";
import { useCountry } from "../ApplicationState/hooks";
import { ErrorBoundary } from "../ErrorBoundary";
import { sendError } from "../ErrorBoundary/helpers";
import { Slider } from "../Slider";
import { Filter } from "./filter";
import styles from "./filterAndSort.module.scss";
import sharedStyle from "./index.module.scss";
import { Sort } from "./sort";
import type { SkiAreaListParameters } from "./types";

export const FilterAndSortBar: React.FC = () => {
    const country = useCountry();
    const { data: countries } = useSkiAreaCountries();
    const router = useRouter();
    const href = getRoute({ parameters: { pageName: "skiAreaList" }, country });
    const searchParams = useSearchParams();
    const hasSearchBar =
        searchParams.has("reviews") ||
        searchParams.has("seasonMonths") ||
        searchParams.has("country") ||
        searchParams.has("locationCode");
    const locationCode = searchParams.get("locationCode");
    const { data: location } = useLocation(locationCode);

    try {
        const onFilter = (): void => {
            const newParams = new URLSearchParams(searchParams.toString());
            if (searchParams.get("status") === "open") {
                newParams.delete("status");
            } else {
                newParams.set("status", "open");
            }
            router.push(`${href}?${newParams.toString()}`);
        };

        const onDeleteFilter = (
            paramName: keyof SkiAreaListParameters | "distanceLocation",
            index?: number
        ): void => {
            const newParams = new URLSearchParams(searchParams.toString());
            switch (paramName) {
                case "reviews":
                    if (index !== undefined) {
                        const reviews = searchParams.get("reviews")?.split(",");
                        if (reviews && reviews.length > 1) {
                            newParams.set(
                                "reviews",
                                reviews.filter((_, i) => i !== index).join(",")
                            );
                        } else {
                            newParams.delete("reviews");
                        }
                        router.push(`${href}?${newParams.toString()}`);
                    }
                    break;
                case "seasonMonths":
                    if (index !== undefined) {
                        const seasonMonth = searchParams
                            .get("seasonMonths")
                            ?.split(",");
                        if (seasonMonth && seasonMonth.length > 1) {
                            newParams.set(
                                "seasonMonths",
                                seasonMonth
                                    .filter((_, i) => i !== index)
                                    .join(",")
                            );
                        } else {
                            newParams.delete("seasonMonths");
                        }
                        router.push(`${href}?${newParams.toString()}`);
                    }
                    break;
                case "country":
                    newParams.delete("country");
                    router.push(`${href}?${newParams.toString()}`);
                    break;
                case "distanceLocation":
                    // delete all filters except the three types from above, the user has selected
                    // also reset page to 1
                    newParams.delete("locationCode");
                    newParams.delete("distanceLocation");
                    newParams.delete("latitude");
                    newParams.delete("longitude");
                    newParams.delete("sortType");
                    newParams.delete("sortDirection");
                    newParams.delete("page");
                    router.push(`${href}?${newParams.toString()}`);
                    break;
            }
        };

        return (
            <>
                <div className={styles.bar}>
                    <div
                        className={cn(
                            sharedStyle.item,
                            searchParams.get("status") === "open" &&
                                sharedStyle.itemActive
                        )}
                        onClick={onFilter}
                        role="button"
                        tabIndex={0}
                        data-tracking-status-open
                    >
                        Jetzt geöffnet
                    </div>
                    <div className={styles.flexbox}>
                        <ErrorBoundary>
                            <Sort />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <Filter />
                        </ErrorBoundary>
                    </div>
                </div>
                {hasSearchBar ? (
                    <div className={styles.activeFilters}>
                        <Slider minHeight="48px">
                            <div
                                className={styles.sliderContent}
                                role="list"
                                data-testid="filterList"
                            >
                                {locationCode && location?.name ? (
                                    <div
                                        className={sharedStyle.item}
                                        key="distanceLocation"
                                        onClick={() => {
                                            onDeleteFilter("distanceLocation");
                                        }}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <span className="icon-pin" />
                                        {location.name}
                                        <span
                                            className={cn(
                                                styles.deleteFilterIcon,
                                                "icon-cross"
                                            )}
                                        />
                                    </div>
                                ) : null}
                                {searchParams
                                    .get("reviews")
                                    ?.split(",")
                                    .map((reviewKey, index) => (
                                        <div
                                            className={sharedStyle.item}
                                            key={reviewKey}
                                            onClick={() => {
                                                onDeleteFilter(
                                                    "reviews",
                                                    index
                                                );
                                            }}
                                            role="button"
                                            tabIndex={0}
                                        >
                                            <span
                                                className={
                                                    reviewMap[
                                                        reviewKey as SkiAreaReviewType
                                                    ].icon
                                                }
                                            />
                                            {
                                                reviewMap[
                                                    reviewKey as SkiAreaReviewType
                                                ].text
                                            }
                                            <span
                                                className={cn(
                                                    styles.deleteFilterIcon,
                                                    "icon-cross"
                                                )}
                                            />
                                        </div>
                                    ))}
                                {searchParams
                                    .get("seasonMonths")
                                    ?.split(",")
                                    .map((month, index) => (
                                        <div
                                            className={sharedStyle.item}
                                            key={month}
                                            onClick={() => {
                                                onDeleteFilter(
                                                    "seasonMonths",
                                                    index
                                                );
                                            }}
                                            role="button"
                                            tabIndex={0}
                                        >
                                            {dayjs()
                                                .month(parseInt(month) - 1)
                                                .format("MMM")}
                                            <span
                                                className={cn(
                                                    styles.deleteFilterIcon,
                                                    "icon-cross"
                                                )}
                                            />
                                        </div>
                                    ))}
                                {countries?.items &&
                                    countries.items.length > 0 &&
                                    searchParams.get("country") && (
                                        <div
                                            className={sharedStyle.item}
                                            onClick={() => {
                                                onDeleteFilter("country");
                                            }}
                                            role="button"
                                            tabIndex={0}
                                        >
                                            {
                                                countries.items.find(
                                                    (country) =>
                                                        country.code ===
                                                        searchParams.get(
                                                            "country"
                                                        )
                                                )?.name
                                            }

                                            <span
                                                className={cn(
                                                    styles.deleteFilterIcon,
                                                    "icon-cross"
                                                )}
                                            />
                                        </div>
                                    )}
                            </div>
                        </Slider>
                    </div>
                ) : null}
            </>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
