/**
 * returns array with int value from start to end, including end!
 * so start=0, end=3 will return [0, 1, 2, 3]
 * @param start
 * @param end
 */
export const range = (start: number, end: number): Array<number> => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};
