import useSWR, { type SWRResponse } from "swr";
import type { Nullable } from "../../../@types/generic";
import { useCountry } from "../../../components/ApplicationState/hooks";
import { QUERY_NAME_SKI_AREA_COUNTRIES } from "../../cacheKeys";
import { getSkiAreaCountries } from "../api/skiAreaCountries";
import type { SkiAreaCountries } from "../types/SkiAreaCountries";

export const useSkiAreaCountries = (): SWRResponse<
    Nullable<SkiAreaCountries>
> => {
    const country = useCountry();
    const { data, isLoading, ...rest } = useSWR(
        [QUERY_NAME_SKI_AREA_COUNTRIES],
        () => getSkiAreaCountries(country)
    );

    return {
        data,
        isLoading: isLoading || data === undefined,
        ...rest,
    };
};
