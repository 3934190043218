import React from "react";
import type { Nullable } from "../../@types/generic";
import { cn } from "../../helpers/classNames";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./styles.module.scss";

type Props = {
    isOpen: Nullable<boolean>;
};

export const SkiAreaOpeningBadge: React.FC<Props> = ({ isOpen }) => {
    try {
        if (isOpen === null) {
            return null;
        }

        return (
            <div
                className={cn(
                    styles.openingInfo,
                    isOpen && styles.openingInfoIsOpen
                )}
            >
                {isOpen ? "Jetzt geöffnet" : "Jetzt geschlossen"}
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
