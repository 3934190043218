"use client";

import { useSearchParams } from "next/navigation";
import React, { useState } from "react";
import { cn } from "../../helpers/classNames";
import { getWindow } from "../../helpers/getWindow";
import { ErrorBoundary } from "../ErrorBoundary";
import { sendError } from "../ErrorBoundary/helpers";
import { Lightbox } from "../Lightbox";
import styles from "./filter.module.scss";
import { FilterLightbox } from "./filterLightbox";
import sharedStyle from "./index.module.scss";

export const Filter: React.FC = () => {
    const window = getWindow();
    const [isOpen, setIsOpen] = useState(false);
    const searchParams = useSearchParams();

    try {
        const count =
            (searchParams.has("country") ? 1 : 0) +
            (searchParams.get("reviews")?.split(",").length ?? 0) +
            (searchParams.get("seasonMonths")?.split(",").length ?? 0);

        const lightboxCallback = (): void => {
            window?.requestAnimationFrame(() => setIsOpen(!isOpen));
        };

        return (
            <>
                <div
                    className={cn(
                        sharedStyle.overlayTrigger,
                        isOpen && sharedStyle.overlayTriggerOpen
                    )}
                    onClick={() => setIsOpen(!isOpen)}
                    role="button"
                    tabIndex={0}
                >
                    <span
                        className={cn(
                            styles.filter,
                            count > 0 && styles.filterActive,
                            "icon-filter"
                        )}
                    />
                    Filter
                    {count > 0 ? ` (${count.toString()})` : null}
                </div>
                {isOpen ? (
                    <Lightbox
                        callback={lightboxCallback}
                        closeIconColor="almostBlack"
                        trackPageImpression="openAndClose"
                        triggerAdReload="openAndClose"
                        overlaySetTargeting="ski_filter_overlay"
                        overlayType="ski_filter"
                    >
                        <ErrorBoundary>
                            <FilterLightbox closeCallback={lightboxCallback} />
                        </ErrorBoundary>
                    </Lightbox>
                ) : null}
            </>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
