import type { SkiAreaListParameters } from "./types";

export const SKIAREA_LIST_ITEMS_PER_PAGE = 14;
export const SESSION_STORAGE_KEY_FOR_SKI_AREA_LIST_PARAMS =
    "wcom_ski_area_list_params";
export const DEFAULT_SKI_AREA_FILTER_PARAMETERS: {
    page: number;
    sortDirection: SkiAreaListParameters["sortDirection"];
    sortType: SkiAreaListParameters["sortType"];
} = {
    page: 1,
    sortDirection: "descending",
    sortType: "slopes",
};
