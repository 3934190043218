import { useRouter, useSearchParams } from "next/navigation";
import React, { useState } from "react";
import type { Nullable } from "../../@types/generic";
import dayjs from "../../app/common/dayjs";
import reviewMap from "../../app/common/skiAreaReviewMapping";
import { sendEventToNativeApp } from "../../bridge/helpers";
import { cn } from "../../helpers/classNames";
import { getWindow } from "../../helpers/getWindow";
import { range } from "../../helpers/range";
import { getRoute } from "../../helpers/urls/getRoute";
import { useSkiAreaCountries } from "../../network/matlocq/queries/SkiAreaCountries";
import type { SkiAreaReviewType } from "../../network/matlocq/types/skiArea";
import type { SkiAreaCountry } from "../../network/matlocq/types/SkiAreaCountries";
import { useStickyAdOffsets } from "../Ad/hooks";
import { useCountry, useIsWebview } from "../ApplicationState/hooks";
import { Button } from "../Button";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./filterLightbox.module.scss";
import { getSkiAreaListParams } from "./helpers";
import sharedStyle from "./index.module.scss";

type Props = {
    closeCallback: () => void;
};

export const FilterLightbox: React.FC<Props> = ({ closeCallback }) => {
    const country = useCountry();
    const window = getWindow();
    const isWebview = useIsWebview();
    const searchParams = useSearchParams();
    const _params: Record<string, string> = {};
    searchParams.forEach((value, key) => {
        _params[key] = value;
    });
    const params = getSkiAreaListParams(_params);

    const { data: countries } = useSkiAreaCountries();
    const { bottom } = useStickyAdOffsets();
    const router = useRouter();
    const href = getRoute({ parameters: { pageName: "skiAreaList" }, country });

    const [selectedReviews, setSelectedReviews] = useState<
        Array<SkiAreaReviewType>
    >(params.reviews ?? []);
    const [selectedMonths, setSelectedMonths] = useState<Array<number>>(
        params.seasonMonths ?? []
    );
    const [selectedCountry, setSelectedCountry] = useState<
        Nullable<SkiAreaCountry>
    >(
        () =>
            countries?.items.find(
                (country) => country.code === params.country?.toUpperCase()
            ) ?? null
    );
    const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

    try {
        const onAttributeClick = (reviewType: SkiAreaReviewType): void => {
            const newSelectedReviews = selectedReviews.includes(reviewType)
                ? selectedReviews.filter((r) => r !== reviewType)
                : [...selectedReviews, reviewType];

            setSelectedReviews(newSelectedReviews);
        };

        const onMonthClick = (month: number): void => {
            const newSelectedMonths = selectedMonths.includes(month)
                ? selectedMonths.filter((r) => r !== month)
                : [...selectedMonths, month].sort((a, b) => a - b);

            setSelectedMonths(newSelectedMonths);
        };

        const onCountryClick = (country: Nullable<SkiAreaCountry>): void => {
            setSelectedCountry(country);
            setIsCountryDropdownOpen(false);
        };

        const resetFilter = (): void => {
            setSelectedReviews([]);
            setSelectedMonths([]);
            setSelectedCountry(null);
        };

        const closeLightbox = (): void => {
            const newParams = new URLSearchParams(searchParams);
            newParams.set("page", "1");
            if (selectedReviews.length) {
                newParams.set("reviews", selectedReviews.join(","));
            } else {
                newParams.delete("reviews");
            }
            if (selectedMonths.length) {
                newParams.set("seasonMonths", selectedMonths.join(","));
            } else {
                newParams.delete("seasonMonths");
            }
            if (selectedCountry?.code) {
                newParams.set("country", selectedCountry.code);
            } else {
                newParams.delete("country");
            }
            const url = `${href}?${newParams.toString()}`;
            if (!isWebview) {
                router.push(url);
            } else {
                sendEventToNativeApp({
                    type: "navigate",
                    url,
                    prevUrl: window
                        ? window.location.pathname +
                          window.location.search +
                          window.location.hash
                        : "",
                });
            }

            closeCallback();
        };

        return (
            <div className={styles.wrapper}>
                <div className={styles.headline}>Skigebiet Suche</div>
                <div className={styles.subHeadline}>Was ist dir wichtig?</div>
                <div className={styles.filterWrapper} role="listbox">
                    {Object.keys(reviewMap).map((_reviewType) => {
                        const reviewType = _reviewType as SkiAreaReviewType;
                        const review = reviewMap[reviewType];
                        return (
                            <div
                                className={cn(
                                    sharedStyle.item,
                                    selectedReviews.includes(reviewType) &&
                                        sharedStyle.itemActive
                                )}
                                key={reviewType}
                                onClick={() => onAttributeClick(reviewType)}
                                role="option"
                                tabIndex={0}
                                aria-selected={
                                    selectedReviews.includes(reviewType)
                                        ? "true"
                                        : "false"
                                }
                                data-tracking-review={reviewType}
                            >
                                <span className={review.icon} />
                                {review.text}
                            </div>
                        );
                    })}
                </div>
                <div className={styles.subHeadline}>
                    Wann möchtest Du fahren?
                </div>
                <div className={styles.filterWrapper} role="listbox">
                    {range(0, 11).map((month) => (
                        <div
                            className={cn(
                                sharedStyle.item,
                                selectedMonths.includes(month + 1) &&
                                    sharedStyle.itemActive
                            )}
                            key={month}
                            onClick={() => onMonthClick(month + 1)}
                            role="option"
                            tabIndex={0}
                            aria-selected={
                                selectedMonths.includes(month + 1)
                                    ? "true"
                                    : "false"
                            }
                            data-tracking-month={month + 1}
                        >
                            {dayjs().month(month).format("MMM")}
                        </div>
                    ))}
                </div>
                <div className={styles.subHeadline}>Land</div>
                {countries && (
                    <div className={styles.countrySelect}>
                        <div
                            className={cn(
                                styles.select,
                                isCountryDropdownOpen && styles.selectOpen
                            )}
                            onClick={() =>
                                setIsCountryDropdownOpen(!isCountryDropdownOpen)
                            }
                            role="combobox"
                            aria-expanded={isCountryDropdownOpen}
                            aria-controls="country-dropdown"
                            tabIndex={0}
                            data-tracking-country
                        >
                            <span>
                                {selectedCountry?.name ?? "Alle Länder"}
                            </span>
                            <span className="icon-dropdown" />
                        </div>
                        {isCountryDropdownOpen && (
                            <div
                                className={styles.dropdown}
                                id="country-dropdown"
                            >
                                <div
                                    className={styles.dropdownItem}
                                    onClick={() => onCountryClick(null)}
                                    role="option"
                                    aria-selected={selectedCountry === null}
                                    tabIndex={0}
                                >
                                    Alle Länder
                                </div>
                                {countries.items.map((country) => (
                                    <div
                                        className={styles.dropdownItem}
                                        key={country.code}
                                        onClick={() => onCountryClick(country)}
                                        role="option"
                                        aria-selected={
                                            selectedCountry?.code ===
                                            country.code
                                        }
                                        tabIndex={0}
                                    >
                                        {country.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                <div
                    className={styles.buttonContainer}
                    style={{
                        bottom,
                    }}
                >
                    <Button
                        variant="snowPrimary"
                        fullWidth
                        onClick={closeLightbox}
                    >
                        Skigebiete anzeigen
                    </Button>
                    <button
                        className={styles.removeFilterButton}
                        onClick={resetFilter}
                    >
                        Alle löschen
                    </button>
                </div>
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
