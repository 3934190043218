import { fetcher } from "..";
import type { CountryType, Nullable } from "../../../@types/generic";
import type { SkiAreaCountries } from "../types/SkiAreaCountries";

export const getSkiAreaCountries = (
    country: CountryType
): Promise<Nullable<SkiAreaCountries>> =>
    fetcher<SkiAreaCountries>(
        { path: `/ski-areas/countries` },
        { country }
    ).catch(() => null);
