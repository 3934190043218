"use client";

import { useRouter, useSearchParams } from "next/navigation";
import React, { useState } from "react";
import { cn } from "../../helpers/classNames";
import { getRoute } from "../../helpers/urls/getRoute";
import { useLocateMe } from "../../hooks/useLocateMe";
import { useCountry, useIsWebview } from "../ApplicationState/hooks";
import { sendError } from "../ErrorBoundary/helpers";
import { SpinningSun } from "../SpinningSun";
import { DEFAULT_SKI_AREA_FILTER_PARAMETERS } from "./config";
import sharedStyle from "./index.module.scss";
import styles from "./sort.module.scss";
import type { SkiAreaListParameters } from "./types";

export const Sort: React.FC = () => {
    const country = useCountry();
    const searchParams = useSearchParams();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoadingPosition, setIsLoadingPosition] = useState<
        false | SkiAreaListParameters["sortDirection"]
    >(false);
    const router = useRouter();
    const { locateMe, permissionDenied, isError } = useLocateMe();
    const sortTypeRaw = searchParams.get("sortType");
    const isDistanceLocationSortType = sortTypeRaw === "distanceLocation";
    const sortType = ["distanceLocation", "distance", "slopes"].includes(
        sortTypeRaw as string
    )
        ? sortTypeRaw === "distanceLocation"
            ? "distance"
            : sortTypeRaw
        : DEFAULT_SKI_AREA_FILTER_PARAMETERS.sortType;
    const sortDirection =
        searchParams.get("sortDirection") === "ascending" ||
        searchParams.get("sortDirection") === "descending"
            ? (searchParams.get("sortDirection") as "ascending" | "descending")
            : DEFAULT_SKI_AREA_FILTER_PARAMETERS.sortDirection;
    const href = getRoute({ parameters: { pageName: "skiAreaList" }, country });
    const isWebview = useIsWebview();

    try {
        const selectSorting = (
            type: Extract<
                SkiAreaListParameters["sortType"],
                "distance" | "slopes"
            >,
            direction: Extract<
                SkiAreaListParameters["sortDirection"],
                "ascending" | "descending"
            >
        ): void => {
            const newParams = new URLSearchParams(searchParams);
            newParams.set("sortType", type);
            newParams.set("sortDirection", direction);
            newParams.set("page", "1");
            newParams.delete("locationCode");

            if (type === "slopes") {
                newParams.delete("latitude");
                newParams.delete("longitude");
                setIsOpen(false);
                router.push(`${href}?${newParams.toString()}`);
            } else {
                setIsLoadingPosition(direction);
                void locateMe(false)
                    .then((currentLocation) => {
                        if (currentLocation) {
                            if (
                                "isLoading" in currentLocation &&
                                currentLocation.isLoading
                            ) {
                                newParams.delete("latitude");
                                newParams.delete("longitude");

                                setIsLoadingPosition(direction);
                                return;
                            } else if ("location" in currentLocation) {
                                if (currentLocation.location?.latitude) {
                                    newParams.set(
                                        "latitude",
                                        currentLocation.location.latitude.toString()
                                    );
                                }

                                if (currentLocation.location?.longitude) {
                                    newParams.set(
                                        "longitude",
                                        currentLocation.location.longitude.toString()
                                    );
                                }
                            }
                        }

                        setIsLoadingPosition(false);
                        setIsOpen(false);
                        router.push(`${href}?${newParams.toString()}`);
                    })
                    .catch(() => {
                        setIsLoadingPosition(false);
                    });
            }
        };

        return (
            <>
                <div
                    className={cn(
                        sharedStyle.overlayTrigger,
                        isOpen && sharedStyle.overlayTriggerOpen
                    )}
                    onClick={() => setIsOpen(!isOpen)}
                    role="button"
                    tabIndex={0}
                >
                    <span
                        className={
                            sortDirection === "descending"
                                ? "icon-sort_desc"
                                : "icon-sort_asc"
                        }
                    />
                    {isDistanceLocationSortType
                        ? "Sortieren"
                        : sortType === "slopes"
                          ? "Pistengröße"
                          : "In meiner Nähe"}
                </div>
                <div
                    className={cn(styles.overlay, isOpen && styles.overlayOpen)}
                >
                    <div>
                        <div className={styles.headline}>
                            Pistengröße (in km)
                        </div>
                        <div className={styles.items}>
                            <div
                                className={cn(
                                    sharedStyle.item,
                                    sortType === "slopes" &&
                                        sortDirection === "descending" &&
                                        sharedStyle.itemActive,
                                    styles.item
                                )}
                                data-tracking-slopes-descending
                                role="button"
                                tabIndex={0}
                                onClick={() =>
                                    selectSorting("slopes", "descending")
                                }
                            >
                                <>
                                    <span className="icon-sort_desc" />
                                    größte zuerst
                                </>
                            </div>
                            <div
                                className={cn(
                                    sharedStyle.item,
                                    sortType === "slopes" &&
                                        sortDirection === "ascending" &&
                                        sharedStyle.itemActive,
                                    styles.item
                                )}
                                data-tracking-slopes-ascending
                                role="button"
                                tabIndex={0}
                                onClick={() =>
                                    selectSorting("slopes", "ascending")
                                }
                            >
                                <span className="icon-sort_asc" />
                                kleinste zuerst
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={styles.headline}>
                            In meiner Nähe (bis 100 km)
                        </div>
                        {permissionDenied ? (
                            <div className={styles.locateMeError}>
                                {`Ortungs-Erlaubnis wurde von Ihnen blockiert. Bei
                                Bedarf können Sie diese Erlaubnis in den
                               ${isWebview ? "Systemeinstellungen" : "Website-Einstellungen"} wieder erteilen.`}
                            </div>
                        ) : (
                            <>
                                <div className={styles.items}>
                                    <div
                                        className={cn(
                                            sharedStyle.item,
                                            !isDistanceLocationSortType &&
                                                isLoadingPosition !==
                                                    "descending" &&
                                                sortType === "distance" &&
                                                sortDirection === "ascending" &&
                                                sharedStyle.itemActive,
                                            styles.item,
                                            isLoadingPosition ===
                                                "descending" &&
                                                sharedStyle.itemDisabled
                                        )}
                                        data-tracking-distance-descending
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => {
                                            if (!isLoadingPosition) {
                                                selectSorting(
                                                    "distance",
                                                    "ascending"
                                                );
                                            }
                                        }}
                                    >
                                        {isLoadingPosition === "ascending" ? (
                                            <SpinningSun size={20} />
                                        ) : (
                                            <>
                                                <span className="icon-sort_asc" />
                                                nächste zuerst
                                            </>
                                        )}
                                    </div>
                                    <div
                                        className={cn(
                                            sharedStyle.item,
                                            !isDistanceLocationSortType &&
                                                isLoadingPosition !==
                                                    "ascending" &&
                                                sortType === "distance" &&
                                                sortDirection ===
                                                    "descending" &&
                                                sharedStyle.itemActive,
                                            styles.item,
                                            isLoadingPosition === "ascending" &&
                                                sharedStyle.itemDisabled
                                        )}
                                        data-tracking-distance-ascending
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => {
                                            if (!isLoadingPosition) {
                                                selectSorting(
                                                    "distance",
                                                    "descending"
                                                );
                                            }
                                        }}
                                    >
                                        {isLoadingPosition === "descending" ? (
                                            <SpinningSun size={20} />
                                        ) : (
                                            <>
                                                <span className="icon-sort_desc" />
                                                weiteste zuerst
                                            </>
                                        )}
                                    </div>
                                </div>
                                {isError && (
                                    <div className={styles.locateMeError}>
                                        Es ist ein Fehler bei der Ortung
                                        aufgetreten. Bitte versuche es erneut.
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
