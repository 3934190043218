"use client";

import type React from "react";
import type { PageSearchParams } from "../../app/types";
import { getWindow } from "../../helpers/getWindow";
import { useIsPersistingForbidden } from "../ApplicationState/hooks";
import { SESSION_STORAGE_KEY_FOR_SKI_AREA_LIST_PARAMS } from "./config";

type Props = {
    params: PageSearchParams;
};

export const SessionStorage: React.FC<Props> = ({ params }) => {
    const window = getWindow();
    const isPersistingForbidden = useIsPersistingForbidden();

    if (!isPersistingForbidden) {
        window?.sessionStorage.setItem(
            SESSION_STORAGE_KEY_FOR_SKI_AREA_LIST_PARAMS,
            JSON.stringify(params)
        );
    }

    return null;
};
