import type { PageSearchParams } from "../../app/types";
import type { SkiAreaReviewType } from "../../network/matlocq/types/skiArea";
import {
    DEFAULT_SKI_AREA_FILTER_PARAMETERS,
    SKIAREA_LIST_ITEMS_PER_PAGE,
} from "./config";
import type { SkiAreaListParameters } from "./types";

const getOffsetFromCurrentPage = (currentPage: string | number): number => {
    if (typeof currentPage === "number") {
        return (
            SKIAREA_LIST_ITEMS_PER_PAGE * currentPage -
            SKIAREA_LIST_ITEMS_PER_PAGE
        );
    }
    return (
        SKIAREA_LIST_ITEMS_PER_PAGE * parseInt(currentPage) -
        SKIAREA_LIST_ITEMS_PER_PAGE
    );
};

export const getSkiAreaListParams = (
    params: PageSearchParams
): SkiAreaListParameters => {
    return {
        limit: SKIAREA_LIST_ITEMS_PER_PAGE,
        offset: getOffsetFromCurrentPage(
            params.page ?? DEFAULT_SKI_AREA_FILTER_PARAMETERS.page.toString()
        ),
        sortType:
            params.sortType === "distanceLocation"
                ? "distance"
                : params.sortType === "distance" || params.sortType === "slopes"
                  ? params.sortType
                  : DEFAULT_SKI_AREA_FILTER_PARAMETERS.sortType,
        sortDirection:
            params.sortDirection === "ascending" ||
            params.sortDirection === "descending"
                ? params.sortDirection
                : DEFAULT_SKI_AREA_FILTER_PARAMETERS.sortDirection,
        latitude:
            typeof params.latitude === "string"
                ? parseFloat(params.latitude)
                : undefined,
        longitude:
            typeof params.longitude === "string"
                ? parseFloat(params.longitude)
                : undefined,
        reviews:
            typeof params.reviews === "string"
                ? (params.reviews.split(",") as Array<SkiAreaReviewType>)
                : undefined,
        status:
            params.status === "open" ? (params.status as "open") : undefined,
        seasonMonths:
            typeof params.seasonMonths === "string"
                ? params.seasonMonths.split(",").map((month) => parseInt(month))
                : undefined,
        country:
            typeof params.country === "string" ? params.country : undefined,
    };
};
