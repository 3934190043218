import React from "react";
import { cn } from "../../helpers/classNames";
import type { AvalancheLevels } from "../../network/matlocq/types/skiArea";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./styles.module.scss";

const warningTexts: Record<AvalancheLevels, string> = {
    1: "Kleine Lawinen sind möglich",
    2: "Mittelgroße Lawinen sind möglich",
    3: "Sehr große Lawinen sind möglich",
    4: "Extrem große Lawinen zu erwarten",
    5: "Extrem große Lawinen zu erwarten",
};

type Props = {
    warningLevel: AvalancheLevels;
    minimumShowLevel: AvalancheLevels;
};

export const AvalancheInformation: React.FC<Props> = ({
    warningLevel,
    minimumShowLevel,
}) => {
    try {
        if (warningLevel < minimumShowLevel) {
            return null;
        }

        return (
            <div
                className={cn(
                    styles.warning,
                    styles[`level${warningLevel.toString()}`]
                )}
            >
                <span className={cn(styles.icon, "snow-icon-warnings")}></span>
                {warningTexts[warningLevel]}
            </div>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
