import type { Nullable } from "../../@types/generic";
import { formatNumber } from "./formatNumber";

type FormatMetersOptions = {
    unit: "km" | "m" | "m-or-km" | "cm";
    precision?: number;
    fallback?: string;
    showUnit?: boolean;
};

/**
 * format given meters to formatted string
 * @param value in meters
 * @param options
 * options.precision is default 0 and will be the parameter for Number.toFixed().
 * options.unit is required and will be the unit for the returned string.
 * With m-or-km, values under 1000m will be returned in meters, otherwise in kilometers.
 */
export const formatDistance = (
    value: Nullable<undefined | number>,
    options: FormatMetersOptions
): string => {
    const precision = options.precision ?? 0;
    const showUnit =
        typeof options.showUnit === "boolean" ? options.showUnit : true;

    if (typeof value === "number") {
        switch (options.unit) {
            case "km":
                return `${formatNumber(value / 1000, precision)}${
                    showUnit ? " km" : ""
                }`;
            case "m":
                return `${formatNumber(value, precision)}${
                    showUnit ? " m" : ""
                }`;
            case "m-or-km":
                return value < 1000
                    ? `${formatNumber(value, precision)}${showUnit ? " m" : ""}`
                    : `${formatNumber(value / 1000, precision)}${
                          showUnit ? " km" : ""
                      }`;
            case "cm":
                return `${formatNumber(value * 100, precision)}${
                    showUnit ? " cm" : ""
                }`;
        }
    }
    return options.fallback ?? "k.A.";
};
